/**
* VARIABLES
* -----------------------------------------------------------------------------
*/
$base-color: #FEFDFD;
$base-bg-color: #3F3552;
$border-dark: rgba($base-bg-color, 0.88);

/**
* INCLUDES
* -----------------------------------------------------------------------------
*/
// flag-icons
@import '~flag-icons/sass/flag-icons';

// Bootstrap parts
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/dropdown";


/**
* GLOBAL
* -----------------------------------------------------------------------------
*/
* {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
html, body {
  //height: 100%;
  margin: 0;
  padding: 0;
}
body {
  background: $base-bg-color;
  color: $base-color;
  font-size: 100%;
  font-weight: 300;
  line-height: 1.3;
  /**/
  -webkit-font-smoothing: antialiased;
}


/**
* TEXT SELECT
* -----------------------------------------------------------------------------
*/
::-moz-selection {
  background: #CB4D59;
  color: #FEFDFD;
  text-shadow: none;
}
::selection {
  background: #CB4D59;
  color: #FEFDFD;
  text-shadow: none;
}
::-moz-selection {
  background: #CB4D59;
  color: #FEFDFD;
  text-shadow: none;
}


/**
* CLEARFIX
* -----------------------------------------------------------------------------
*/
.clearfix {
  display: inline-block;
  &:after {
    clear: both;
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
  }
}
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}


/**
* ELEMENTS
* -----------------------------------------------------------------------------
*/
p {
  margin: 0 0 15px;
}
ul {
  list-style: disc outside;
  margin: 0 0 15px;
  padding: 0 0 0 15px;
}
ol {
  list-style: outside decimal;
  margin: 0 0 15px 20px;
  padding: 0;
}
img {
  height: auto;
  max-width: 100%;
}
em {
  font-style: italic;
}
strong {
  font-weight: bold;
}
a {
  &:link {
    color: #CB4D59;
    font-weight: 600;
    text-decoration: underline;
  }
  &:visited {
    color: #CB4D59;
    font-weight: 300;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
::-webkit-input-placeholder {
  opacity:1;
}
:-moz-placeholder {
  opacity:1;
}
::-moz-placeholder {
  opacity:1;
}
:-ms-input-placeholder {
  opacity:1;
}


/**
* HEADER
* -----------------------------------------------------------------------------
*/
.site-header {
  padding: 24px 0;
}

.header-container {
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 20px;
  .author-link {
    text-align: left;
    font-size: 0.75em;
    text-decoration: none;
  }
  .title {
    font-size: 2.75em;
    display: flex;
    justify-content: center;
  }
  .home-page-local-lang {
    //background-color: #FFFFFF;
    z-index: 99;
    background: transparentize(#FFFFFF, 0.75);
    box-sizing: inherit;
    width: fit-content;
    float: right;
    cursor: pointer;

    a {
      text-decoration: none;
      font-weight: bold;
      font-size: 0.8em;
      padding: 0.25rem 1rem;
      color: #212529;
    }
    .dropdown-item {
      &.active,
      &:active {
        background-color: #0F8AFD;
        color: white;
      }
      &.hover,
      &:hover {
        background-color: lighten(#0F8AFD, 15%);
      }
    }
  }
}


/**
* FOOTER
* -----------------------------------------------------------------------------
*/
footer {
  height: 40px;
  text-align: center;
}



/**
* RIBBON
* -----------------------------------------------------------------------------
*/
.ribbon,
.ribbon:link {
  font-size: 13px;
  height: 12.1em;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-decoration: none;
  text-indent: -999999px;
  top: 0;
  width: 12.1em;
  z-index: 9999;
}
.ribbon {
  &:before, &:after {
    display: block;
    position: absolute;
    height: 1.54em;
    top: 3.23em;
    right: -3.23em;
    width: 15.38em;
    /**/
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:before {
    content: "";
    background-color: #CB4D59;
    padding: .38em 0;
    pointer-events: auto;
    /**/
    -webkit-box-shadow: 0 .15em .23em 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 .15em .23em 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 .15em .23em 0 rgba(0, 0, 0, 0.5);
  }
  &:after {
    content: attr(title);
    color: #fff;
    font-size: 1em;
    line-height: 1.54em;
    margin: .15em 0;
    padding: .15em 0;
    text-decoration: none;
    text-shadow: 0 -.08em rgba(0, 0, 0, 0.5);
    text-align: center;
    text-indent: 0;
  }
}




/**
* BUTTON SOUNDBOARD  &  CHARACTER CONTAINER
* -----------------------------------------------------------------------------
*/
.btn-container,
.characters-container,
.episodes-container,
.paginator-container {
  .btn,
  .reset {
    &, &:hover, &:focus {
      background-color: #CB4D59;
      background-image: none;
      border: 0;
      border-bottom: 3px solid #8B121E;
      border-radius: 0;
      color: #FEFDFD;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857;
      outline: none;
      padding: 5px 20px;
      position: relative;
      text-decoration: none;
      vertical-align: top;
      white-space: nowrap;
      -moz-user-select: none;
      -webkit-appearance: none;
    }
    &:active {
      top:3px;
      border-bottom: 0;
    }
  }
  .btn-play {
    &, &:hover, &:focus {
      background: #18ae90;
      border-bottom: 0;
      padding: 10px 10px 10px 50px;
    }
    &:before {
      content: "";
      background: url('./img/play.svg') no-repeat center center;
      font-size: 1em;
      height: 50px;
      left: 0;
      position: absolute;
      top: 5px;
      width: 50px;
    }
    &.playing {
      &:before {
        background: url('./img/stop.svg') no-repeat center center;
      }
    }
  }
  .audio-buffer {
    height: 3px;
  }
  .btn-info {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 30px;
    padding-top: 5px;
    z-index: 9;
    &:before {
      position: absolute;
      content: "";
      background: url('./img/info.svg') no-repeat center center;
      font-size: 1em;
      height: 20px;
      width: 20px;
      left: 0;
    }
  }
  .btn-character,
  .btn-episode,
  .btn-paginator {
    &, &:hover, &:focus {
      background: #2b7a6a;
      border-bottom: 3px solid #1c4842;
      padding: 5px 10px;
      margin-left: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    &.active {
      background: darken(#2b7a6a, 15%);
    }
  }
}
.characters-container,
.episodes-container {
  background-color: darken($base-bg-color, 10%);
  background-image: none;
  border: 0;
  border-radius: 0;
  margin-bottom: 10px;
  .header {
    background-color: darken($base-bg-color, 17%);
    padding: 10px 20px;
    cursor: pointer;
    > * {
      margin: 0;
    }
  }
  .content {
    padding: 5px 20px;
    padding-top: 10px;
    &.hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 2s, opacity 2s linear;
    }
    &.visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 2s linear;
    }
  }
}
.episodes-container {
  .content {
    overflow: auto;
    max-height: 200px;
  }
}
.paginator-container {
  background-color: darken($base-bg-color, 10%);
  background-image: none;
  border: 0;
  border-radius: 0;
  margin-bottom: 10px;
  .header {
    background-color: darken($base-bg-color, 17%);
    padding: 10px 20px;
    > * {
      margin: 0;
    }
  }
  .content {
    padding: 5px 20px;
    padding-top: 10px;
  }
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}



/**
* FORM
* -----------------------------------------------------------------------------
*/
::-webkit-input-placeholder {
  color: #AEAEAE;
}
:-moz-placeholder {
  color: #AEAEAE;
}
::-moz-placeholder {
  color: #AEAEAE;
}
:-ms-input-placeholder {
  color: #AEAEAE;
}
label {
  display: none;
}
input {
  margin: 0;
}
input[type="text"] {
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 0;
  box-shadow: none;
  color: #555;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857;
  outline: none;
  padding: 6px 12px;
  height: 34px;
}




/**
* GENERAL
* -----------------------------------------------------------------------------
*/
noscript p {
  margin: 10px 0;
  text-align: center;
}
.hidden {
  display: none !important;
}
.invisible {
  visibility: hidden;
}
#main {
  margin: 0 auto;
  padding: 0;
  width: 98%;
}

.btn-container {
  margin-bottom: 10px;
  > div {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > .btn {
      margin: 0;
      text-align: center;
      width: 120px;
      + .btn {
        margin-left: 20px;
      }
    }
  }
}


#filter {
  text-align: center;
}
#filter form {
  overflow: hidden;
  position: relative;
}
#filter input[type="text"] {
  padding-right: 32px;
  text-align: left;
}
#filter .btn {
  height: 34px;
  left: -5px;
  position: relative;
}
#filter .btn-reset {
  background: url('./img/close.svg') no-repeat center center;
  border: 0;
  font-size: 1em;
  /*display: none;*/
  height: 24px;
  left: -38px;
  margin: 0 -34px 0 0;
  outline: none;
  position: relative;
  padding: 0;
  width: 24px;
  /**/
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.list ul {
  list-style-type: none;
  margin: 2em 0 0;
  padding: 0;
  text-align: center;
}
.react-tooltip-inner {
  line-height: 1;
}
.list ul li {
  display: inline-block;
  height: 65px;
  line-height: 43px;
  margin: 0 0 1em;
  overflow: hidden;
  position: relative;
}
.list ul li a {
  height: 62px;
  width: 43px;
}
.list ul li > a {
  right: 0;
}
.list ul li div {
  margin-right: 0px;
}
.list ul li div a {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.list ul li div span {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.share-link {
  background-color: #FFFFFF;
  border: 1px solid #999;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  color: #333;
  margin: 0 auto;
  padding: 10px;
  position: absolute;
  text-align: center;
  z-index: 10001;
}
.share-link textarea {
  width: 100%;
}
.share-link .likely {
  margin-top: 5px;
}
.video-wrapper {
  height: 0;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
}
.video-wrapper iframe {
  height: 100%;
  left: 0;
  max-height: 315px;
  position: absolute;
  top: 0;
  width: 100%;
}
#error404 {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
}
#error404 .video-wrapper {
  margin-bottom: 20px;
}

.locale-btn-container {
  .btn {
    background-color: #1c4842;
    border-bottom-color: #1c4842;
    margin: 15px;
    &:hover,
    &:focus {
      background-color: lighten(#1c4842, 5%);
      border-bottom-color: lighten(#1c4842, 5%);
    }
  }
}

footer {
  margin-top: 40px;
}


/**
* MEDIA QUERIES
* -----------------------------------------------------------------------------
*/
@media only screen and (min-width:768px) {
  .list ul li {
    width: 46%;
    margin-left: 1%;
    margin-right: 1%;
  }
}
@media only screen and (min-width:1024px) {
  .list ul li {
    width: 370px;
  }
}
@media only screen and (max-width:767px) {
  .list ul li {
    width: 95%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
@media only screen and (max-width:430px) {
}

@media only screen and (max-width:320px) {
  input[type="text"] {
    width: 60%;
  }
  .btn, .btn:hover, .btn:focus,
  a.btn, a.btn:hover, a.btn:focus {
    width: 30%;
  }
}